
import Vue from 'vue';

export default Vue.extend({
  name: 'Export',

  computed: {
    currentAward () {
      return this.$store.state.currentAward
    },
  },

  created () {
    this.$store.commit('setExportMode', true)
    this.$store.commit('setDarkMode', false)
  },

  destroyed () {
    this.$store.commit('setExportMode', false)
  }
});
